@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Permanent Marker", cursive, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  -webkit-text-decoration-line: inherit;
          text-decoration-line: inherit;
  color: inherit;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 3rem;
  color: #eddde4;
  background: rgba(6, 8 ,19 , 0.85);
}

.icon {
  color: #eddde4;
}

.icon:hover {
  -webkit-transform: scale3d(1.5, 1.5, 1);
          transform: scale3d(1.5, 1.5, 1);
}
.navActive {
  color: #cc2674;
}

.form-label {
  display: block;
  font-size: 1.2rem;
  margin: 0.625rem 0 0.25rem;
  color: #eddde4;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.75rem;
  margin: 0.2rem 0;
  font-size: 1.2rem;
  color: #eddde4;
  border: 2px solid #133eb9;
  border-radius: 12px;
  background-color: #102967;
}

.form-textarea {
  height: 18rem;
}

.footer-link {
  display: flex;
  float: right;
  padding: 15px;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #04060f;
  box-shadow: inset 0 0 5px grey;
  border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #102967;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
